<template>
  <div id="header">
      <b-navbar id="navi" toggleable="lg" class="light" fixed="top" 
        style="background-color:rgba(255,255,255,1);" >
        <b-navbar-brand to="/"> 
          <img alt="Vue logo" src="../assets/kuvalogosta.webp" style="height: 50px;">
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item to="/">Ajankohtaista</b-nav-item>
            <b-nav-item to="/tyonhaku">Työnhakutietoa</b-nav-item>
            <b-nav-item to="/tietoa">Tietoa meistä</b-nav-item>
            <b-nav-item to="/mukaan">Ota yhteyttä</b-nav-item>
          </b-navbar-nav>
          
        </b-collapse>
      </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    msg: String
  }
}
</script>