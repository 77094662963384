import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import firebase from "firebase";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/tyonhaku',
    name: 'Tyonhaku',
    component: () => import( '../views/Tyonhaku.vue')
  },
  {
    path: '/mukaan',
    name: 'Mukaan',
    component: () => import( '../views/Mukaan.vue')
  },
  {
    path: '/tietoa',
    name: 'Tietoa',
    component: () => import( '../views/Tietoa.vue')
  },
  {
    path: '/tietoa/tunnista',
    name: 'Tunnista',
    component: () => import( '../views/sub/Tunnista.vue')
  },
  {
    path: '/tietoa/piilo',
    name: 'Piilo',
    component: () => import( '../views/sub/Piilo.vue')
  },
  {
    path: '/tietoa/haastattelu',
    name: 'Haastattelu',
    component: () => import( '../views/sub/Haastattelu.vue')
  },
  {
    path: '/tietoa/yrittajyys',
    name: 'Yrittajyys',
    component: () => import( '../views/sub/Yrittajyys.vue')
  },
  {
    path: '/dashboard/login',
    name: 'Login',
    component: () => import( '../views/dashboard/Login.vue')
  },
  {
    path: '/dashboard/reset',
    name: 'Reset',
    component: () => import( '../views/dashboard/Reset.vue')
  },
  {
    path: '/dashboard/home',
    name: 'Dashboard',
    component: () => import( '../views/dashboard/Dashboard.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import( '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.authRequired)) {
    if (firebase.auth().currentUser) {
      next();
    } else {
      alert('Sinun pitää kirjautua sisään ensin.');
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default router
