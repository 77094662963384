<template>
  <div id="footer">
      <div class="container-fluid" style="margin-top: 35px;">
      <div class="row">
        <div class="col"></div>
        <div class="col" style="padding-bottom: 25px;">
          <a href="https://www.youtube.com/channel/UCfxWKnU6i6_99XZPfouszGQ"><img class="img-fluid fluid-grow some" alt="" src="../assets/some/youtube.webp" ></a>
        </div>
        <div class="col" style="padding-bottom: 25px;">
          <a href="https://www.linkedin.com/in/urapurje-vinssi-ryhm%C3%A4t-3052531a1/"><img class="img-fluid fluid-grow some" alt="" src="../assets/some/linkedin.webp" ></a>
        </div>
        <div class="col" style="padding-bottom: 25px;">
          <a href="https://goo.gl/maps/D34V4FaU3jG2"><img class="img-fluid fluid-grow some" alt="" src="../assets/some/googlemaps.webp" ></a>
        </div>
        <div class="col" style="padding-bottom: 25px;">
          <a href="https://www.facebook.com/Urapurje/"><img class="img-fluid fluid-grow some" alt="" src="../assets/some/facebook.webp" ></a>
        </div>
        <div class="col" style="padding-bottom: 25px;">
          <a href="https://twitter.com/urapurje"><img class="img-fluid fluid-grow some" alt="" src="../assets/some/twitter.webp" ></a>
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<style type="text/css">
.some {
  width: 100%;
  max-width: 100px;
}
</style>