<template>
	<div class="home">
		<div class="container-fluid">
			<div class="row no-gutters">
				<div class="col">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kaksinaistapoydanaaressa.webp" style="width: 100%;">
				</div>
				<div class="col">
					<img class="img-fluid fluid-grow" alt="" src="../assets/kahvikuppi.webp" style="width: 100%;"> 
				</div>
				<div class="col">
					<img class="img-fluid fluid-grow" alt="" src="../assets/miespuhuukannykkaan.webp" style="width: 100%;">
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-5">
					<h2>Ilmoittaudu mukaan</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-5" style="padding-bottom: 25px;">
					<!--p>Ryhmien kokoontumispaikka on hyvien liikenneyhteyksien päässä Turussa Kupittaalla. Ryhmiä kokoontuu myös Salossa.</p-->
					<p>{{ajankohtaista}}</p>
					<a href="https://forms.gle/UwCfxMNnKNy4LZPN8 " type="button" class="btn btn-danger btn-t btn-danger-t">Ilmoittaudu mukaan!</a>
					<!--a href="/mukaan" type="button" class="btn btn-danger btn-t btn-danger-t">Ilmoittaudu täältä!</a-->
				</div>
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/tyopoyta.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-5">
					<h2>Vinssi-pienryhmät</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-5">
					<p>Vinssi pienryhmätoiminta antaa monipuolista tukea työnhakuusi. Vinssi on vertaistoimintaa, jonka tarkoitus on auttaa osallistujia löytämään, tunnistamaan ja markkinoimaan omaa osaamistaan. Vuonna 2020 Vinssi-ryhmien toimintaa rahoittavat akavalaiset ammattiliitot. Rahoitusta haetaan lisäksi mm. kunnilta ja ELY-keskukselta. Ryhmä tarjoaa oivan tilaisuuden verkostoitua samassa tilanteessa olevien oma alansa ammattilaisten kanssa.
					</p>
					<p>Vinssi pienryhmässä parannat työnhakutaitojasi, saat uusia näkökulmia työnhakuun sekä opit tunnistamaan ja tulkitsemaan omaa osaamistasi niin, että osaamista voidaan siirtää uudelle alalle. Ryhmässä huomioimme uratoiveet ja saamme tukea toisiltamme. Huomioithan, että osallistuminen edellyttää sitoutumista ryhmän toimintaan.
					</p>
					<p>Ryhmiin voivat osallistua kaikki varsinaissuomalaiset korkeakoulutetut ja vastaavaa kokemusta omaavat työnhakijat ja työttömyysuhan alaiset.
					</p>
					<p>Tule mukaan Vinssi-pienryhmään! Yhteen ryhmään valitaan noin 10 eri taustan omaavaa osallistujaa. Ryhmä kokoontuu <b>9 kertaa, kerran viikossa 3 tuntia kerrallaan.</b> Tapaamiset ovat viikon välein ja jokaisen tapaamisen välillä on aktivoivia kotitehtäviä. Ryhmälle on luotu valmis ohjelmamalli, jota muokataan osallistujien toiveiden mukaan. Toimintaa koordinoi ryhmäohjaaja, joka on itse osallistunut johonkin aiemmista ryhmistä.</p>
					<p>Vinssi-ryhmän tapaamisten teemat ovat:</p>
					<div style="margin-left: 15px; margin-right: 15px;">
						<ul>
							<li>Tavoitteet ja odotukset</li>
							<li>Työkokemus ja merkittävimmät aikaansaannokset</li>
							<li>Ammatilliset osaamisalueet (myös meta-osaaminen)</li>
							<li>Persoonallisuus ja motivaatio</li>
							<li>Arvot sekä niiden merkitys työllistymisen kannalta</li>
							<li>Myyvä ansioluettelo, neuvot ura-asiantuntijalta</li>
							<li>Henkilökohtainen SWOT-analyysi</li>
							<li>Piilotyöpaikat</li>
							<li>LinkedIn-profiili ja sosiaalinen media työnhaussa</li>
							<li>Haastatteluihin valmistautuminen ja videohaastattelun harjoittelu</li>
							<li>Ansioluetteloiden kommentointi</li>
							<li>Hakemustyöpaja </li>
							<li>Informaatiota ja sparrausta yrittäjyyteen liittyen</li>
							<li>Päättötyöt, jossa osallistujat esittelevät ammatillisen tavoitteensa ja osaamisensa keihäänkärjet</li>
							<li>Yhteenveto ryhmätyöskentelystä ja toimintasuunnitelma jatkoa ajatellen</li>
							<li>Mahdollisten lisä-/ seurantatapaamisten sopiminen</li>
						</ul>
					</div>

				</div>
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/opiskelukuva.webp" style="width: 100%;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<!--div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-5">
					<h2>Meistä muualla</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row mt-4">
				<div class="col"></div>
				<div class="col-sm-3">
				<img class="img-fluid fluid-grow" alt="" src="../assets/lehtijutut/kuvalehtijutusta1.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5" style="padding-bottom: 25px; font-style: italic;">
					<p>"Vinssissä antoisinta oli tutustua muihin samassa tilanteessa oleviin ja jakaa kokemuksia. Sain huomata, etten ole ajatusteni kanssa yksin. "</p>

					<button @click="open_link('https://lehti.tek.fi/tyoelama/vinssi-kirkastaa-kasityksen-omista-kyvyista')" type="button" class="btn btn-dange btn-t btn-danger-t">TEK Lehti</button>
				</div>
				<div class="col"></div>
			</div>
			<div class="row mt-4">
				<div class="col"></div>
				<div class="col-sm-3">
				<img class="img-fluid fluid-grow" alt="" src="../assets/lehtijutut/kuvalehtijutusta2.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5" style="padding-bottom: 25px; font-style: italic;">
					<p>"Aktiivisesta ryhmästä saa uutta virtaa työnhakuunsa."</p>

					<button @click="open_link('https://lehti.tek.fi/tyoelama/vinssista-virtaa-tyonhakuun')" type="button" class="btn btn-danger btn-t btn-danger-t">TEK Lehti</button>
				</div>
				<div class="col"></div>
			</div>
			<div class="row mt-4">
				<div class="col"></div>
				<div class="col-sm-3">
				<img class="img-fluid fluid-grow" alt="" src="../assets/lehtijutut/kuvalehtijutusta3.webp" style="width: 100%;">
				</div>
				<div class="col-sm-5" style="padding-bottom: 25px; font-style: italic;">
					<p>"Sain parannusehdotuksia cv:hen ja hakemuksen tekoon. Sain henkistä tukea, mikä lisää itsevarmuutta."</p><p>
					"Sain ajatuksen toiselta jäseneltä, joka oli saanut palkkatukipaikan. Tämän jälkeen lähdin selvittämään palkkatukiasiaa ja sattumalta oli yksi paikka auki, jonka sain."</p>

					<button @click="open_link('https://www.ts.fi/lukijoilta/3502814/Vertaistuesta+nakokulmia+ja+viretta+tyonhakuun')" class="btn btn-danger btn-t btn-danger-t">Lue lisää täältä</button>
				</div>
				<div class="col"></div>
			</div>
		</div-->
		<div class="container-fluid">
			<div class="row" style="margin-top: 25px; margin-bottom: 15px;">
				<div class="col"></div>
				<div class="col-sm-5">
					<h2>Vertaistuki - miksi se on tärkeää</h2>
				</div>
				<div class="col"></div>
			</div>
			<div class="row">
				<div class="col"></div>
				<div class="col-sm-5" style="padding-bottom: 25px;">
					<p>Vertaistuki vahvistaa sinun taitojasi ja vahvistaa osaamista ja oman osaamisen tunnistamista</p>
					<ul><li>työnhakutaitoja ja toimintatapoja</li><li>luoda jatkuvuutta henkilökohtaisessa työnhakuprosessissa</li><li>saada rakentavaa palautetta</li><li>kehittää henkilökohtaisia verkostoja</li><li>saada tukea hyvinvoinnin ja elämänhallinnan parantamiseksi sekä</li><li>työllistyä omaa osaamista vastaaviin tehtäviin.</li></ul>
				</div>
				<div class="col-sm-3">
					<img class="img-fluid fluid-grow" alt="" src="../assets/keskusteluryhma.webp" style="width: 100%; padding-bottom: 15px;">
				</div>
				<div class="col"></div>
			</div>
		</div>
		<Social/>
	</div>
</template>

<script>
import firebase from "firebase";
import Social from '../components/Social.vue'

export default {
	name: 'Home',
  data() {
    return {
      ajankohtaista: "",
    };
  },
  methods: {
    open_link(link){
    console.log("open_link");
    window.open(link, "_blank");
    }
  },
components: {
    Social
  },
  mounted() {
    var ajankohtaista_setting = firebase.database().ref('settings/ajankohtaista');
    ajankohtaista_setting.on('value', (snapshot) => {
      const data = snapshot.val();
      this.ajankohtaista = data;
    });
  },
  beforeDestroy() {
    
  }
}
</script>

<style type="text/css">
.some {
	width: 100%;
	max-width: 100px;
}
.btn-t {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-danger-t {
    color: #fff !important;
    background-color: #dc3545;
    border-color: #dc3545;
}
</style>
