<template>
  <div id="app">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'


export default {
  data() {
    setTimeout(() => {
                    this.isLoading = false;
                }, 15000);
    return {
      isLoading: true,
      fullPage: true
    }
  },
  components: {
//    Loading,
    Header,
    Footer
  },
  mounted() {
    window.addEventListener('load', () => {
         // run after everything is in-place
         this.isLoading = false;
    })
},
}

</script>

<style>
#app {
  padding-top: 60px;
}

</style>
