<template>
  <div id="social">
    <v-speed-dial
        v-model="dialShare"
        absolute
        dark
        right
        bottom
        fixed
        direction="left"
        open-on-hover
      >
        <template v-slot:activator>
          <v-btn fab bottom color="dark">
            <v-icon v-if="dialShare">mdi-close</v-icon>
            <v-icon v-else>mdi-share-variant</v-icon>
          </v-btn>
        </template>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}&t=${pageTitle}`"
          target="_blank"
        >
          <v-icon>mdi-facebook</v-icon>
        </v-btn>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`https://twitter.com/share?url=${pageUrl}&text=${pageTitle}`"
          target="_blank"
        >
          <v-icon>mdi-twitter</v-icon>
        </v-btn>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`https://pinterest.com/pin/create/button/?url=${pageUrl}&media=&description=${pageTitle}`"
          target="_blank"
        >
          <v-icon>mdi-pinterest</v-icon>
        </v-btn>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`"
          target="_blank"
        >
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`https://wa.me/?text=Urapurje.fi%20${pageUrl}`"
          target="_blank"
        >
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
        <v-btn
          dark
          fab
          bottom
          color="green"
          small
          :href="`mailto:?subject=${pageTitle}&body=${pageUrl}`"
          target="_blank"
        >
          <v-icon>mdi-mail</v-icon>
        </v-btn>
      </v-speed-dial>
  </div>
</template>

<script>
export default {
  name: 'Social',
  props: {
    msg: String
  },
  methods: {},
  data() {

    return {
      dialShare: false,
      pageTitle: "Urapurje.fi - " + this.$router.currentRoute.name,
      pageUrl: "https://www.urapurje.fi" + this.$router.currentRoute.path,
    };
  }
}
</script>

<style type="text/css">

</style>