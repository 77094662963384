import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './router'
import firebase from "firebase/app";
import vuetify from './plugins/vuetify';
import JsonExcel from "vue-json-excel";

Vue.use(VueRouter)
Vue.use(BootstrapVue)

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = false


const configOptions = {
  apiKey: "AIzaSyA8nphwnuT_-b3y9_R10SXBLqZHcCwu5PY",
  authDomain: "urapurje-4fb1f.firebaseapp.com",
  databaseURL: "https://urapurje-4fb1f-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "urapurje-4fb1f",
  storageBucket: "urapurje-4fb1f.appspot.com",
  messagingSenderId: "152777924714",
  appId: "1:152777924714:web:c745cae046b6a30345ea13",
  measurementId: "G-KPHRMP8X6G"

};

firebase.initializeApp(configOptions);
//firebase.analytics();


new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')